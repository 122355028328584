// src/components/Device.js
import React, { useState, useRef, useEffect } from 'react';
import { getDevices, updateDevice } from './api'; 

const Device = () => {
    const [devices, setDevices] = useState([]);
    const lastUpdate = useRef(0)

    const getDeviceList = () => {                
      console.log("Retrieving devices data")
      
      getDevices().then((val) => { if (val && Object.keys(val).length !== 0) { setDevices(val); } else { }})
      
    };

    // Load devices on first load only
    useEffect(() => {getDeviceList();},[])
    
    const update = (device_id, name, text) => {        
        let updateTime = new Date().getTime()
        lastUpdate.current = updateTime
        // Debounce with 300ms interval -- broken in that there's no "OR DIFFERENT FIELD", need a lastupdatedata check too
        setTimeout(() => {doUpdate(updateTime, {'device_id': device_id, [name]: text})}, 300);
    }

    const doUpdate = (updTime, data) => {
        if (lastUpdate.current === updTime) {
            updateDevice(data)
        }
    }

    return (
        <div>
            <h2>Device Page</h2>
            <table>
            <tr><th>Device ID</th><th>Splits</th><th>Event ID</th><th>Priority</th><th>Time Offset</th><th>Registered On</th></tr>
            {devices.map((row) => (
                <tr><td>{row['device_id']}</td>
                    <td><input name="splits" defaultValue={row['splits']} onChange={text => update(row['device_id'], 'splits', text.target.value)}/></td>
                    <td><input name="event_id" defaultValue={row['event_id']} onChange={text => update(row['device_id'], 'event_id', text.target.value)}/></td>
                    <td><input name="priority" defaultValue={row['priority']} onChange={text => update(row['device_id'], 'priority', text.target.value)}/></td>
                    <td>{row['time_offset']}</td>
                    <td>{row['registered']}</td></tr>
            ))}
            </table>

            <iframe srcDoc="<!DOCTYPE html>" src="about:blank" title="Hidden Frame" scrolling="no" frameBorder="0" id="devHiddenFrame" name="devHiddenFrame" style={{'opacity': 100, 'height': 100}} />
        </div>
    );
};

export default Device;
