/* ---------------------------------------------------------------
-- Login REACT subpage for TBF timing manager

*/

import React from 'react';
import { TextField } from '@mui/material';

const Login = () => {
    const authtoken='TbfTimingManagerBackendEditor'

    return (
        
        <div>
            <h2>Login Page</h2>
            <TextField id='auth' />
            <Button onClick={window.sessionStorage.setItem("authtoken",document.getElementById('auth').value)} />
        </div>

    );
};

export default Login;
