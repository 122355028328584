import React from 'react';
import './App.css'; // Import your CSS file
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Athlete from './components/Athlete';
import Event from './components/Event';
import Device from './components/Device';
import Waves from './components/Waves';
import Announcer from './components/Announcer';
import Times from './components/Times';
import RegImporter from './components/Importer';
import Utilities from './components/Utilities';
import Login from './components/Login';

const App = () => {
    return (        
        <Router>
            <meta name="robots" content="noindex" />
            <div className="app-container">
                {/* Sidebar */}
                <div className="sidebar">
                    <img src="https://totalbodyfitness.com/site/wp-content/uploads/2019/03/tbf_logo.png" alt="TBF Logo" width="100%"/>
                    <ul>
                        <li><Link to="/athlete">Athlete</Link></li>
                        <li><Link to="/event">Event</Link></li>
                        <li><Link to="/device">Device</Link></li>
                        <li><Link to="/waves">Waves</Link></li>
                        <li><Link to="/times">Times</Link></li>
                        <li><Link to="/announcer">Announcer</Link></li>
                        <li><Link to="/import">Import Reg</Link></li>
                        <li><Link to="/util">Utilities</Link></li>
                    </ul>
                </div>

                {/* Main content */}
                <div className="content">
                    <Routes>
                        <Route path="/athlete" element={<Athlete />} />
                        <Route path="/event" element={<Event />} />
                        <Route path="/device" element={<Device />} />
                        <Route path="/waves" element={<Waves />} />
                        <Route path="/announcer" element={<Announcer />} />
                        <Route path="/times" element={<Times />} />
                        {/* Default route if no match (optional) */}
                        <Route path="*" element={<Announcer />} />
                        <Route path="/import" element={<RegImporter />} />
                        <Route path="/util" element={<Utilities />} />
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

export default App;
