// src/components/Announcer.js
import React, { useState, useRef, useEffect } from 'react';
import { Button, TableContainer, Table, TableRow, TableBody, TableCell } from '@mui/material';
import { getRegistrationByBib } from './api'; 

export default function Announcer() {

    const [bib, setBib] = useState('Bib');
    const [racers, setRacers] = useState([{}]);
    const [lastPress, setLastPress] = useState(0);

    const updateBib = (number) => {                
        if (number === 'Bib') {
            setBib('Bib')
        } else if (bib.length < 10) {

          let newBib = bib
          // Limiting the bib entry to 10 digits
          if (bib === 'Bib' || Date.now() - lastPress > 1500) {
            newBib = number;
          } else {
            newBib = newBib + number;
          }
          console.log("Calling update racer info on " + bib)          
          setBib(newBib)
          getRegistrationByBib(newBib).then((val) => { if (val && Object.keys(val).length !== 0) { setRacers([val,...racers.slice(0,8)]) }})
        }

        setLastPress(Date.now())

      };



    return (
        <div className="announcer">
            <div className="bibheader">{bib}</div>
            <div style={{textAlign: 'center'}}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((number) => (
                  <Button
                    variant="contained"
                    onClick={() => updateBib(String(number))}>
                    {number}
                  </Button>
                ))}

       {/*         <Button
                    variant="contained"
                    onClick={() => updateBib('Bib')}>
                    &lt;==
                  </Button>*/}

            </div>

            <TableContainer>
                <Table><TableBody>
                {racers.map((row) => (
                    <TableRow>
                        <TableCell>{row['bib']}</TableCell>
                        <TableCell>{row['first_name']} {row['last_name']}</TableCell>
                        <TableCell>{row['gender']}{row['age']}</TableCell>
                        <TableCell>{row['division']}</TableCell>
                    </TableRow>
                ))}
                </TableBody></Table>
            </TableContainer>
        </div>
    );
};

