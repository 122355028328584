/* ---------------------------------------------------------------
-- Athlete REACT subpage for TBF timing manager

2024-09-07  Fixed logic on timer name, key was regenerating and data entry was horrid

*/

import React, { useState, useMemo } from 'react';
import { Autocomplete, TextField, Button } from '@mui/material';
import { getRaceDetails, getEventId } from './api'; 


const Event = () => {
    const [evt, setEvt] = useState({'name':'', 'id':'', 'nraces':'', 'date':'', 'type': ''});
    const [tmr, setTmr] = useState(['']);
    const [spl, setSpl] = useState([]);
    const [splName, setSplName] = useState([]);
    const [race, setRace] = useState([]);
    const [mapLength, setMapLength] = useState(1);

    const updateTimer = (id, val) => {
        let t = tmr;
        t[id] = val;
        // Prevents undefined value when creating new entry field, prepopulating array with next entry blank
        if (id > t.length) { t[id+1] = '';}
        setMapLength(t.length);
        setTmr(t);
    }

    const createRaceArray = (val) => {
        // Pre-sizes and fills arrays since mid-array item entry with causes inconsistency between array index and fields
        // Also prevents uncontrolled/controlled error messaging
        // Can I use this to consolidate to an array of objects per race?
        setEvt({...evt, 'nraces': val});   
        
        if (parseInt(val) > 0 ) {

            setRace(new Array(parseInt(val)+1).fill(''));
            setSplName(new Array(parseInt(val)*5+5).fill(''));
            setSpl(new Array(parseInt(val)*5+5).fill(''));
                  
        }
    }

    const printStates = () => {
        console.log(evt, tmr, spl, splName, race)
    }

    const checkDate = (val) => {
        var dparts = val.split('/');
        if (parseInt(dparts[2]) > 2000) {
            setEvt({...evt, 'date': dparts[2] + "-" + dparts[0] + "-" + dparts[1]});
        } else if (parseInt(dparts[2]) < 100) {
            setEvt({...evt, 'date': '20' + dparts[2] + "-" + dparts[0] + "-" + dparts[1]});
        } else {
            setEvt({...evt, 'date': val})
        }
    }

    return (
        
        <div>
            <form action="https://tbf.my-races.com/api" method="post" target="hiddenFrame" id="registration_form">
            <h2>Event Page</h2>
            <Button onClick={printStates()} label="Log States"/>
            
            <div>
              <TextField variant="standard" label="Event ID" name="event_id" value={evt['id']} style={{'width':'100%'}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEvt({...evt, 'id': event.target.value}); }} 
              /><br/>
              <TextField variant="standard" label="Event Name" name="event_name" value={evt['name']} style={{'width':'100%'}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEvt({...evt, 'name': event.target.value}); }} 
              /><br/>
              <TextField variant="standard" label="Event Date" name="event_date" value={evt['date']} style={{'width':'100%'}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setEvt({...evt, 'date': event.target.value}); }} 
                            onBlur={(event: React.ChangeEvent<HTMLInputElement>) => { checkDate(event.target.value); }} 
              /><br/>
              <Autocomplete options={['Triathlon', 'MTB', 'MTB Enduro', 'Swim Run']} freeSolo size="small"  value={evt['type']} key={evt['name']+"eventtypekey"}
                              renderInput={(params) => 
                                <TextField {...params} variant="standard" name="event_type" label="Event Type" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                    setEvt({...evt, 'type': event.target.value});
                                }}/>
                         }/>
              <TextField variant="standard" label="Number of Races" name="number_of_races" id="number_of_races" value={evt['nraces']} style={{'width':'100%'}}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { createRaceArray(e.target.value); }} 
              />

                {/*Timers fields - e.g. Start, Run Out, Swim Out, Split 1, Finish Line*/}
                {(Array.from({length: tmr.length}, (v, k) => k+1)).map((r, i) => (
                    
                    <Autocomplete options={['Start', 'Finish Line', 'Swim Out', 'Run Out']} freeSolo size="small" 
                          value={tmr[r]} key={r+"timerkey"} 
                          renderInput={(params) => 
                                <TextField {...params} variant="standard" label="Timer (e.g. Start, Finish Line)"
                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                            setTmr([...tmr.slice(0, r), event.target.value, ...tmr.slice(r+1)]);
                                           }}
                                          name={"timer"+r} /> 
                          }
                    />

                ))}
                
            
            </div>

            {(Array.from({length: evt['nraces']}, (v, k) => k+1)).map((rn, j) => (
                

                <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gridGap: 20, marginTop: 20}}>
                    <div>
                        Race {rn}
                        <TextField variant="standard" label="Race Name" name={"race_name..."+rn} style={{'width':'100%'}}
                                        value={race[rn]} 
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {                                             
                                            setRace( [...race.slice(0,rn), event.target.value, ...race.slice(rn+1)]); 
                                        }} 
                          />
                    </div>



                    <div style={{marginTop: 20, marginBottom: 10}}>

                        {/*Timers fields - e.g. Start, Run Out, Swim Out, Split 1, Finish Line*/}
                        {(Array.from({length: 5}, (v, k) => k+1)).map((r, i) => (
                            
                            <Autocomplete options={['Finish', 'Swim', 'Bike', 'Run']} freeSolo size="small"
                                  onInputChange={(event, newInputValue) => { setSplName([...splName.slice(0,rn*5+r), newInputValue, ...splName.slice(rn*5+r+1)]); }} 
                                  value={splName[rn*5+r]}
                                  renderInput={(params) => 
                            <TextField {...params} variant="standard" label="Split Name" name={"splitname..."+rn+"..."+r} value={splName[rn*5+r]}
                                        /> }
                            />
                        ))}
                    </div>

                    <div style={{marginTop: 20}}>

                        {(Array.from({length: 5}, (v, k) => k+1)).map((r, i) => (
                            <Autocomplete options={['(Finish Line) - (Start)', '(Swim Out) - (Start)', '(Bike Out) - (Swim Out)', '(Finish Line) - (Bike Out)']} 
                                  freeSolo size="small" value={spl[r+5*rn]}
                                  onInputChange={(event, newInputValue) => { setSpl([...spl.slice(0,rn*5+r), newInputValue, ...spl.slice(rn*5+r+1)]); }} 
                                  renderInput={(params) => 
                                        <TextField {...params} variant="standard" label="Split Calculations" name={"split..."+rn+"..."+r} value={spl[r+5*rn]}
                                                 /> }
                            />
                        ))}
                                          
                    </div>
                </div>
            ))}
            <input type="hidden" name="source" value="react" />
            <input type="hidden" name="function" value="event" />
            <input type="hidden" name="action" value="update" />
            <input type="submit" value="Submit" style={{marginTop: 20}}/>
            </form>
            <iframe srcDoc="<!DOCTYPE html>" src="about:blank" scrolling="no" frameBorder="0" title="Hidden Frame" id="hiddenFrame" name="hiddenFrame" style={{'opacity': 100, 'height': 100}} />
        </div>
        



    );
};

export default Event;
