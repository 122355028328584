// api.js

const API_BASE_URL = 'https://tbf.my-races.com/api'; // Replace with your backend API URL

const request = (method, data = null) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, `${API_BASE_URL}`, true);
    xhr.setRequestHeader('Content-Type', 'application/json');

    data['source'] = 'react'

    // parseInt used to prevent malicious code
    const query = new URLSearchParams(window.location.search);
    const eid = query.get('eid')
        if (parseInt(eid) > 0) { data['event_id'] = parseInt(eid) }
    const rid = query.get('rid')
        if (parseInt(rid) > 0) { data['race_id'] = parseInt(rid) }
    // console.log("Query: "+ query)

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };

    if (data) {
      xhr.send(JSON.stringify(data));
    } else {
      xhr.send();
    }
  });
};

export const getEventId = () => {
  return request('POST', {'function': 'event', 'action': 'id'})
}

export const getAllRegistrations = () => {
  return request('GET', '/registration');
};

export const getRegistrationByBib = (id) => {
  return request('POST', {'function': 'registration', 'action': 'bybib', 'bib': id});
};

export const setDNS = (bib) => {
  return request('POST', {'function': 'registration', 'action': 'dns', 'bib': bib});
};

export const updateRegistration = (registration) => {
  return request('POST', Object.assign({}, {'function': 'registration', 'action': 'submit'}, registration));
};

export const getTimesByBib = (id) => {
 return request('POST', {'function': 'times', 'action': 'query', 'bib': id});
};

export const getTimes = (id) => {
 return request('POST', {'function': 'times', 'action': 'query'});
};

export const deleteRegistration = (id) => {
  return request('DELETE', `/registration/${id}`);
};

export const getWaves = () => {
  return request('POST', {'function': 'waves', 'action': 'query'});
}

export const getDevices = () => {
  return request('POST', {'function': 'devices', 'action': 'query'});
}

export const updateDevice = (data) => {
  return request('POST', Object.assign({}, {'function': 'devices', 'action': 'update'}, data));
}

export const setTimesInclude = (id, val) => {
  return request('POST', {'function': 'times', 'action': 'edit', 'entry_id': id, 'include': val});
}

export const getDivisions = (eid) => {
  return request('POST', {'function': 'registration', 'action': 'divisions'});
}

export const getRaceDetails = (eid) => {
  return request('POST', {'function': 'registration', 'action': 'event'});
}

export const updateEvent = (data) => {
  return request('POST', Object.assign({}, {'function': 'event', 'action': 'update'}, data));
}

export const submitTimes = (data) => {
  return request('POST', Object.assign({}, {'function': 'times', 'action': 'submit'}, data))
}