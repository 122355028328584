// src/components/Waves.js
import React, { useState, useRef, useEffect } from 'react';
import { getWaves } from './api'; 



export default function Waves() {

    const [waves, setWaves] = useState([]);
    const refreshRan = useRef(0);
    const lastRan = useRef(-1)

    const fetchData = async () => {
      refreshRan.current += 1;
      try {
        const data = await getWaves();
        setWaves(data);

      } catch (error) {
        console.error('Error fetching waves:', error);
      }
    };

    

    // useEffect(() => {
    //     if (refreshRan.current > lastRan.current) {
    //         fetchData();
    //         lastRan.current = refreshRan.current;
    //     }        
    // }, []);

    return (
        <div>
            <h2>Waves Page</h2>
            <form action="https://tbf.my-races.com/api" method="post" target="hiddenFrame">
                Gun time: <input name="time" type="text" /><br/><br/>
                <input type="hidden" name="source" value="react" />
                <input type="hidden" name="function" value="waves" />
                <input type="hidden" name="action" value="submit" />
                Select divisions:             
                <table>
                {waves.map((row) => (
                    <tr>
                    <td><input type="checkbox" value="True" name={row['event_id'] +"..." +row['race_id'] + "..." + row['division']} /></td>
                        <td>RID: {row['race_id']}</td><td>Div: {row['division']}</td><td>{row['clock_time']}</td>
                    </tr>
                    ))}
                </table>
            <input type="submit" />
            </form>

            <iframe srcDoc="<!DOCTYPE html>" src="about:blank" scrolling="no" frameborder="0" onLoad={() => fetchData()} id="hiddenFrame" name="hiddenFrame" style={{'opacity': 1, 'height': 1}} />

        </div>
    );
};

