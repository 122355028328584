/* ---------------------------------------------------------------
-- Times REACT subpage for TBF timing manager

TO DO:
2024-08-11  Fixed broken priority listing to deal with race issue at TFK3

2024-08-04  Cleaned up useEffect to clear setTimeout on unload and navigation away (kept running in background)
            Added hyperlinks to bib and name  - direct bib addressing on /athlete?##
            Fixed bib filtering

2024-07-27  Use and validate ?eid=X across all React pages and backend

2024-07-15  Added more static fields
            Added bib filter to react.times
--
--------------------------------------------------------------- */

import React, { useState, useRef, useEffect } from 'react';
import { Button, TableContainer, Table, TableRow, TableBody, TableCell, Typography } from '@mui/material';
import { getTimes, setTimesInclude } from './api'; 
import { useLocation, Link } from 'react-router-dom';

export default function Times() {

    const [times, setTimes] = useState([{}]);     // Holds array of times, main page display
    const lastTime = useRef(0);                   // Prevents runaway settimeouts from exceeding certain value
    const [sbox, setSbox] = useState("");         // Handles bib listing in search box
    const location = useLocation();
    const tout = useRef();                        // Index of main setTimeout call

    const getAPITimes = () => {                
        const now = new Date().getTime();


        if (now - lastTime.current > 4000) {
            console.log("Calling API for event times")          
            getTimes().then((val) => { 
                lastTime.current = now;
                if (val && Object.keys(val).length !== 0) { 
                    setTimes(val); 
                }
                clearTimeout(tout.current);
                tout.current = setTimeout(() => {getAPITimes()},30000);
            })
        } else {
            // Adding one ten seconds out, at worst it adds more, but slows down the rate, I don't want to get stuck with NO setTimeouts            
            clearTimeout(tout.current);
            tout.current = setTimeout(() => {getAPITimes()},30000)
        }
    }

    // Load devices on first load only
    useEffect(() => {
        getAPITimes();
    
        return () => {
            clearTimeout(tout.current)
        };
    }, [])

    const setCB = (e) => {
        let val = 0
        if (e.target.checked === true) { val = 1; } else { val = 0; }
        setTimesInclude(e.target.id, val);
    }

    return (
        <div className="timesdiv">
            <div style={{float: 'left'}}><Typography variant="caption">This page refreshes every 30 seconds</Typography></div>
            <div style={{float:'right', marginRight: 200}}>
                <input id="searchbox" type="text" placeholder="" style={{borderRadius:16, paddingLeft: 28, margin:8, width: 100, paddingRight: 50, height:20}} 
                       onChange={val => {setSbox(val.target.value); } } />
                <img alt="" src={require('./search.png')} style={{margin:"0 0 0 -185px", top: 6, position:'relative', width:20, height:20, background:"transparent", objectFit:"cover"}}/>
            </div>
            <TableContainer>
                <Table><TableBody>
                <TableRow>
                <TableCell>Include</TableCell><TableCell>Prio</TableCell><TableCell>Bib</TableCell>
                <TableCell>Time</TableCell><TableCell>Split</TableCell><TableCell>Name</TableCell><TableCell>Division</TableCell>
                </TableRow>
                {times.filter((row) => {if ([...sbox].length > 0) { return(row['bib'].toString() === sbox);} else {return true;}}).map((row) => (                    
                    <TableRow>
                        {/* entry_id, event_id, device_id, clock_time, bib, splits, CAST(include AS CHAR) AS include */ }
                        <TableCell style={{height:8, whiteSpace: 'nowrap', padding: 0, width: 60}}>
                            <input type="checkbox" value="1" defaultChecked={(row['include'] === "1") ? true : false} 
                                   id={row['entry_id']} name={row['entry_id']} onChange={e => setCB(e)}/>  {row['include']}
                        </TableCell>
                        <TableCell style={{height:8, whiteSpace: 'nowrap', padding: 0, width: 60}}>{row['priority']}</TableCell>
                        <TableCell style={{height:8, whiteSpace: 'nowrap', padding: 0, width: 100}}>
                            <Link to={"/athlete?"+row['bib']}>
                                <span style={{color: 'black'}}>{row['bib']}</span>
                            </Link>
                        </TableCell>
                        <TableCell style={{height:8, whiteSpace: 'nowrap', padding: 0, width: 140}}>{row['clock_time']}</TableCell>
                        <TableCell style={{height:8, whiteSpace: 'nowrap', padding: 0, width: 120}}>{row['splits']}</TableCell>
                        <TableCell style={{height:8, whiteSpace: 'nowrap', padding: 0, width: 120}}>
                            <Link to={"/athlete?"+row['bib']}>
                                <span style={{color: 'black'}}>{row['first_name']+" "+row['last_name']}</span>
                            </Link>
                        </TableCell>
                        <TableCell style={{height:8, whiteSpace: 'nowrap', padding: 0}}>{row['division']}</TableCell>
                    </TableRow>
                ))}
                </TableBody></Table>
            </TableContainer>
        </div>
    );
};

