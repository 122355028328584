// src/components/Athlete.js
import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Typography,
         TableContainer, Table, TableRow, TableCell,  } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getRegistrationByBib, getTimesByBib, getDivisions, getRaceDetails, getEventId } from './api'; 
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { updateRegistration } from "./api"
// import { SendIcon } from '@mui/icons-material';


const RegImporter = () => {

    const [racer, setRacer] = useState([{}]);
    const [isOpen, setIsOpen] = useState(true);
    const [divisions, setDivisions] = useState({});
    const [newDivision, setNewDivision] = useState(null);
    const [tableData, setTableData] = useState([{bib: 0}]);
    const [openModal, setOpenModal] = useState(false);
    
    const columns = [
      { field: 'event_id', headerName: 'Event ID', editable: true },
      { field: 'race_id', headerName: 'Race ID', editable: true },
      { field: 'bib', headerName: 'Bib' },
      { field: 'first_name', headerName: 'First Name', editable: true },
      { field: 'last_name', headerName: 'Last Name', editable: true },
      { field: 'division', headerName: 'Division', editable: true },
      { field: 'age', headerName: 'Age', editable: true },
      { field: 'gender', headerName: 'Gender', editable: true },
      { field: 'usat', headerName: 'USAT #', editable: true },
      { field: 'dob', headerName: 'Date of Birth', editable: true },
      { field: 'email', headerName: 'E-mail', editable: true },
      { field: 'address', headerName: 'Address', editable: true },
      { field: 'city', headerName: 'City', editable: true },
      { field: 'state', headerName: 'State', editable: true },
      { field: 'zip', headerName: 'Postal Code', editable: true },
    ];


    const setEventID = () => {
      let eid = prompt("Enter Event ID", "")
      let td = tableData
      for (var row of td) {
        row['event_id'] = eid
      }
      setTableData([...td])
      // console.log(tableData)
    }

    const handleSubmit = () => {
      tableData.forEach((row) => {
        updateRegistration(row)
        const sleep = (ms: number) => new Promise((r) => setTimeout(r, 300));
      })

    }


    function getRowId(row) {
      return row.bib;
    }

    // Called when flow is closed without reaching submit.
    const onClose = () => { 
      setIsOpen(false); 
    }
    // Called after user completes the flow. Provides data array, where data keys matches your field keys. 
    const onSubmit = (data, file) => {}

    const processDivisions = () => {
      // Create list of all divisions in table
      let d = divisions
      tableData.forEach((row) => {
        if (Object.keys(d).includes(row['division']) === false) {
          let newD = {[row['division']]: {rule: '', race_id: ''}} 
          d = {...d, ...newD};
        }
      })
      console.log({...d})
      setDivisions({...d})
     }

    const applyRule = (division, rule, row) => {
      let d = division

      if (rule !== '') { d = rule }
      
      if (rule.substring(0,2) === 'AG') {
        // Age grouping rule
        if (rule.substring(3) === "0") {
          d = (row['gender'] === "M") ? "All Men" : "All Women"
        } else if (rule.substring(3) === "10") {
          d = (row['gender'] === "M") ? "Men " : "Women "
          d += String(Math.floor(row['age'] / 10)*10) + "-" + String(Math.floor(row['age'] / 10)*10+9)
        }  else if (rule.substring(3) === "5") {
          d = (row['gender'] === "M") ? "Men " : "Women "
          d += String(Math.floor(row['age'] / 5)*5) + "-" + String(Math.floor(row['age'] / 5)*5+4)
        } else if (rule.substring(3) === "1") {
          d = (row['gender'] === "M") ? "Boys " : "Girls "
          d += String(row['age'])
        } else if (rule.substring(3) === "2") {
          d = (row['gender'] === "M") ? "Boys " : "Girls "
          d += String(Math.floor(row['age'] / 2)*2) + "-" + String(Math.floor(row['age'] / 2)*2+1)
        }
      }
      
      return d
    }

    const processDivisionChanges = (jsonData) => {
      // Received from closing modal, via submit if jsonData is not blank
      console.log(jsonData)
      if (jsonData === '') {
        setDivisions({})
      } else {
        let td = tableData        
        td.forEach((row) => {
          if (Object.keys(jsonData).includes(row['division']+"race_id")) {
            row['race_id'] = jsonData[row['division']+'race_id']
            row['division'] = applyRule(row['division'], 
                                       jsonData[row['division']+"rule"], 
                                       row)
          }
        })
        setTableData([...td])
        setDivisions({})
      }
    }

    const rowCleanup = (data, addError) => {
      if (data['division'] != null && data['division'].match(/co.*ed/i)) { 
            data = {...data, gender: 'C'}
      } else { 
        data = {...data, gender: data['gender'].substring(0,1) } 
      };

      return data
    }

    const populateTable = (data, file) => {
      if (JSON.stringify(tableData) === '[{"bib":0}]') {
        // First import
        setTableData(data.validData);
        setIsOpen(false);
      } else {
        // Merged import
        let td = tableData
        for (const row of data.validData) {
          if (parseInt(row['bib']) > 0) {
            // Match Row by Bib
            for (var tdrow of td) {
              if (tdrow['bib'] === row['bib']) {
                // Merge Rows
                for (const [key, value] of Object.entries(row)) {
                  if (String(tdrow[key] || '').length < String(row[key] || '').length) {
                    tdrow[key] = row[key]
                  }
                }
              }
            }

          } else if (row['last_name'].length > 0 && row['first_name'].length > 0) {
            // Match Row by First and Last
            for (var tdrow of td) {
              if (String(tdrow['last_name']).toLowerCase() === String(row['last_name']).toLowerCase() && String(tdrow['first_name']).toLowerCase() === String(row['first_name']).toLowerCase()) {
                
                // Merge Rows
                for (const [key, value] of Object.entries(row)) {
                  if (String(tdrow[key] || '').length < String(row[key] || '').length) {
                    tdrow[key] = row[key]
                  }
                }

              }
            }
          } else {
            alert("Row import failed: does not have bib or first+last: " + JSON.stringify(row) + "") 
          }

        }
        setTableData([...td])
        // console.log(tableData)
      }
    }

    const fields = [
      {
        label: "Event ID",  key: "event_id",
        alternateMatches: ["eventid", "eid"],
        fieldType: { type: "input", },
        example: "10", editable: true
      },
      {
        label: "Race ID",  key: "race_id",
        alternateMatches: ["raceid", "rid"],
        fieldType: { type: "input", },
        example: "1", editable: true
      },
      {
        label: "Bib",  key: "bib",
        fieldType: { type: "input", },
        example: "101", editable: true
      },
      {
        label: "First Name", key: "first_name",
        alternateMatches: ["first name", "first", "Name: First name"],
        fieldType: { type: "input", },
        example: "Stephanie", editable: true,
        validations: [{ rule: "required", errorMessage: "First name is required", level: "error", },],
      },
      {
        label: "Last Name", key: "last_name",
        alternateMatches: ["last name", "last", "Name: Last name"],
        fieldType: { type: "input",},
        example: "Smith", editable: true,
        validations: [{ rule: "required", errorMessage: "Last name is required", level: "error",},],
      },
      {
        label: "Division", key: "division",
        fieldType: { type: "input",},
        example: "Women 20-29", editable: true
      },
      {
        label: "Age", key: "age",
        fieldType: { type: "input",},
        example: "39", editable: true
      },
      {
        label: "Gender", key: "gender",
        fieldType: { type: "input",},
        example: "F", editable: true
      },  
      {
        label: "USAT #", key: "usat", 
        alternateMatches: ["membership_status"],
        fieldType: { type: "input",},
        example: "11001001", editable: true
      },                    
      {
        label: "Date of Birth", key: "dob",
        alternateMatches: ["Date of birth"],
        fieldType: { type: "input",},
        example: "01/01/1978", editable: true
      },                     
      {
        label: "E-mail", key: "email",
        alternateMatches: ["e-mail", "Email address"],
        fieldType: { type: "input",},
        example: "rob@alls.house", editable: true
      },                    
      {
        label: "Address", key: "address",
        alternateMatches: ["Contact address: Address1"],
        fieldType: { type: "input",},
        example: "1234 Anytown Dr", editable: true
      },                    
      {
        label: "City", key: "city",
        alternateMatches: ["Contact address: City"],
        fieldType: { type: "input",},
        example: "Rocklin", editable: true
      },                    
      {
        label: "State", key: "state",
        alternateMatches: ["Contact address: State Province Region"],
        fieldType: { type: "input",},
        example: "CA", editable: true
      },                    
      {
        label: "Postal Code", key: "zip",
        alternateMatches: ["zip code", "zipcode", "Contact address: ZIP/Postal code"],
        fieldType: { type: "input",},
        example: "95818", editable: true
      },                    
    ]

    return (
      <div>
      <ReactSpreadsheetImport 
        isOpen={isOpen} onClose={onClose} onSubmit={(data, file) => populateTable(data, file)} fields={fields} 
        rowHook={(data, addError) => {           
          return rowCleanup(data, addError);
        }}
      />

      <Button variant="outlined" sx={{marginRight: 2, marginBottom: 2}} onClick={() => setIsOpen(true)}>Merge File</Button>
      <Button variant="outlined" sx={{marginRight: 2, marginBottom: 2}} onClick={() => {setEventID()}}>Set Event ID</Button>
      <Button variant="outlined" sx={{marginRight: 2, marginBottom: 2}} onClick={() => {processDivisions()}}>Process Divisions</Button>
      <Button variant="contained" sx={{marginRight: 2, marginBottom: 2}} onClick={() => {handleSubmit()}}>Send</Button>

      <DataGrid rows={tableData} columns={columns} getRowId={getRowId} />

      <Dialog open={(Object.keys(divisions).length > 0) ? true : false} onClose={processDivisionChanges} 
              PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  processDivisionChanges(formJson);
                },
              }}
      >
        <DialogTitle>Set Division Parameters</DialogTitle>
        <DialogContentText sx={{ m: 0, p: 2 }}>Options: Div Name, "AG #" 0=Overall, 1,2,5,10yr AGs</DialogContentText>        
        <Table size="small">
        {Object.keys(divisions).map((k, i) => ( 
          <TableRow>
            <TableCell><Typography>{k}</Typography></TableCell>
            <TableCell><TextField id="race_id" name={k+"race_id"} label="Race ID" variant="standard" sx={{ m: 0, p: 2 , width: 150}}/></TableCell>
            <TableCell><TextField id="rule" name={k+"rule"} label="Rule" variant="standard" sx={{ m: 0, p: 2 , width: 150}}/></TableCell>
          </TableRow>
        ))}
        </Table>
        <DialogActions sx={{ m: 0, p: 2 }}>          
          <Button onClick={() => {processDivisionChanges('')}}>Cancel</Button>
          <Button type="Submit">Change</Button>
        </DialogActions>
      </Dialog>
      </div>
    )

};
export default RegImporter;
