/* ---------------------------------------------------------------
-- Athlete REACT subpage for TBF timing manager

TO DO:
  - update race_id to name vs id in combobox.. see https://codesandbox.io/s/material-demo-forked-wlzr8?file=/demo.js

2024-11-07  Removed value={bib} from bib, helps debounce
            Added if (val.bib == racer.bib) to post API logic to make sure I don't set/clear if changes occured, seems to work better (not perfect)

2024-09-06  Disallowed non-numeric in bib
            Adjusted debounce again
            
2024-08-24  Fixed debounce issue with bib data entry rate
            Fixed issue with timing duplications

2024-08-12  registration changed to react+api handling of updates instead of form-submit DOM 
            changed registration update <Button> to reference pending changes and completing changes with variant code
            adjusted clearForm to accept bib as function variable and edited references for persistence where appropriate
            settled for race id: name text box for lookup (vs option render in combobox)
            Added priority to times

2024-08-11  times changed to react+api handling of updates instead of form-submit DOM 
            changed times update <Button> to reference pending changes and completing changes with variant code
            added ?eid override to athlete page and handling bib direct query parameters, removed Event ID field
            Debounced athlete page bib entry to make more responsive to typing

2024-08-04  direct bib addressing via /athlete?##

2024-07-28  Changed times to controlled TextInput, fixed backend logic and extra duplications

2024-07-27  Fixed invalid times queries on bib clear
            Shift all Athlete inputs to React components (TextInput and Autocomplete)
            Fixed bug: Gender clearing on submit (actually not pre-populating)
            Use and validate ?eid=X across all React pages and backend

2024-07-26  Created combolist for division
            Allow multiple date formats in registration
            Fixed empty age and birthdates in registration

2024-07-26  Created with basic functionality

--
--------------------------------------------------------------- */

import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete, TextField, Checkbox, Button, Typography } from '@mui/material';
import { getRegistrationByBib, getTimesByBib, getDivisions, getRaceDetails, getEventId, updateRegistration, submitTimes } from './api'; 
import { useLocation } from 'react-router-dom';

const Athlete = () => {

    const [racer, setRacer] = useState({});             // handles all values on registration side
    const [times, setTimes] = useState([]);             // handles all values on times side
    const [divisions, setDivisions] = useState([]);     // Array for populating combobox
    const [races, setRaces] = useState([]);             // Object for populating combobox of race_id
    const location = useLocation();
    const bibDebounce = useRef(0);
    const [formUpdated, setFormUpdated] = useState([0,0]) // Boolean handling visible appearance of submit button

    // Load devices on first load only
    useEffect(() => {firstLoad();},[])

    const firstLoad = () => {
        getDivision();

        if (parseInt(location.search.substring(1)) > 0) {
            // URL query contains only the bib in ?### format, auto populate (for links from other pages)
            getAthlete(location.search.substring(1));
        }
    }

    const getDivision = () => {
        getRaceDetails().then((val) => { 
            if (val && Object.keys(val).length !== 0) { 
                setDivisions(val['Divisions']); 
                setRaces(objToArray(val['Races']));
            } else { 
            } 
        })

    }

    const objToArray = (rDict) => {
        // Takes a dict of {id: #, name: XX} and changes it to an array of r[id] name
        let r = {};
        rDict.forEach((item, index) => {
            r[item.id] = item.name
        })
        return r
    }

    const debounceGet = (deBib) => {
        let bib = racer.bib

        if (deBib === bib && Date.now() - bibDebounce.current > 600) {
            
            if (bib.length > 0) {
                getRegistrationByBib(bib)
                    .then((val) => { 
                        if (val && Object.keys(val).length !== 0) { 
                            if (val.bib == racer.bib) {
                                setRacer(val);                                
                            } 
                        } else { if (val.bib == racer.bib) { clearForm(bib); } }
                        getTimes(bib);
                    })
            }
        } else {
                console.log("Debounced: now " + Date.now() + " debounce " + bibDebounce.current + " bib " + racer)         
        }
    }

    const getAthlete = (bib) => {                
        bib = bib.replace(/[^0-9]/g, "")
        setRacer({bib: bib})
        // clearForm(bib);
        // setTimes([])

        let ts = Date.now()
        bibDebounce.current = ts
        setTimeout(() => {debounceGet(bib)}, 300)
      
    };

    const getTimes = (bib) => {
        if (bib.length > 0) {
            getTimesByBib(bib).then((val) => { if (val && Object.keys(val).length !== 0) { setTimes(val) } })
        } else {
            setTimes([])
        }
    }

    const clearForm = (bib = '') => {
        // Specify the bib for items that need to persist, such as clearing because bib returns empty
        document.getElementById("registration_form").reset();
        document.getElementById("times_form").reset();
        document.getElementById("bib").value = bib;

        setRacer({'bib':bib, 'first_name':'','last_name':'','event_id':'','race_id':'','division':'','age':'',
          'gender':'','usat':'','dob':'','email':'','address':'','city':'',
           'state':'','zip':''})

        setTimes([])
        setFormUpdated([0,0])
        document.getElementById('bib').focus()
    }


    return (
        <div>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}><h2>Athlete Page</h2></div>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', paddingRight: '40px'}}>
                    <form encType="multipart/form-data" action="" method="post" id="registration_form" 
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                          event.preventDefault();
                          const formData = new FormData(event.currentTarget);
                          const formJson = Object.fromEntries(formData.entries());
                          updateRegistration(formJson).then(() => { clearForm();})}}>
                    <table style={{paddingBottom: '10pt'}}><tbody>
                      <tr><td>Bib</td>
                      <td><TextField id="bib" name="bib" autoFocus sx={{width: '100%'}}
                              variant="standard" onChange={(e) => getAthlete(e.target.value)} /></td></tr>
                      

                      <tr><td>Race ID</td><td><div style={{whiteSpace: 'nowrap'}}>
                        <Autocomplete options={Object.keys(races)} freeSolo size="small" value={racer['race_id']}  key={racer['bib']+"raceidkey"} style={{display: 'inline-block', width: 100}}
                              renderInput={(params) => 
                                <TextField {...params} variant="standard" name="race_id" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                                    setRacer({...racer, 'race_id': event.target.value});
                                    setFormUpdated([1,formUpdated[1]]);
                                    // Somehow set label here to races[event.target.value] 
                                }}/>
                         }/>
                         <Typography style={{display: 'inline-block', verticalAlign: 'bottom', lineHeight: 0.9 }} variant="caption">{Object.keys(races).map((r, i) => (<span>{r}: {races[r]}<br/></span>))}</Typography>
                         </div></td></tr>  
                    
                      

                      <tr><td>First Name</td><td>
                      <TextField variant="standard" id="first_name" name="first_name" value={racer['first_name']} sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'first_name': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>Last Name</td><td>
                      <TextField variant="standard" id="last_name" name="last_name" value={racer['last_name']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'last_name': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>Division</td><td>
                        <Autocomplete options={divisions} freeSolo value={racer['division']} key={racer['bib']+"divisionkey"} sx={{width: '100%'}}
                                    renderInput={(params) => 
                                    <TextField {...params} variant="standard" name="division" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'division': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}/>
                        }/></td></tr>
                      
                      <tr><td>Age</td><td>
                      <TextField variant="standard" id="age" name="age" value={racer['age']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'age': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>Gender</td><td>
                      <Autocomplete options={['M','F','C']} freeSolo value={racer['gender']} key={racer['bib']+"genderkey"} sx={{width: '100%'}}
                                    renderInput={(params) => 
                                    <TextField {...params} variant="standard" label="" name="gender" onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'gender': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}/>
                      }/></td></tr>

                      <tr><td>DOB</td><td>
                      <TextField variant="standard" id="dob" name="dob" value={racer['dob']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'dob': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>USAT</td><td>
                      <TextField variant="standard" id="usat" name="usat" value={racer['usat']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'usat': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>eMail</td><td>
                      <TextField variant="standard" id="email" name="email" value={racer['email']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'email': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>Address</td><td>
                      <TextField variant="standard" id="address" name="address" value={racer['address']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'address': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>City</td><td>
                      <TextField variant="standard" id="city" name="city" value={racer['city']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'city': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>State</td><td>
                      <TextField variant="standard" id="state" name="state" value={racer['state']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'state': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  

                      <tr><td>Zip</td><td>
                      <TextField variant="standard" id="zip" name="zip" value={racer['zip']}   sx={{width: '100%'}}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setRacer({...racer, 'zip': event.target.value}); setFormUpdated([1,formUpdated[1]]);}}
                      /></td></tr>  
                    </tbody></table>

                    <Button type="submit" variant={(formUpdated[0] === 0) ? 'outlined' : 'contained'} size="small">Update Registration</Button>
                    </form>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <form encType="multipart/form-data" action="" method="post" id="times_form" 
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                      event.preventDefault();
                      const formData = new FormData(event.currentTarget);
                      const formJson = Object.fromEntries(formData.entries());
                      submitTimes(formJson).then(() => {getTimes(document.getElementById('bib').value); setFormUpdated([formUpdated[0],0]);})}}>

                    <table style={{paddingBottom: '10pt'}}>
                    <tbody>
                      {times.map((row, i) => (
                        <tr>
                        <td><TextField variant="standard" value={row['clock_time']} name={"clock..." + row['entry_id'] + "..." + row['bib'] +"..."  + racer['event_id']} label="Time"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { let t = [...times]; t[`${i}`]['clock_time'] = event.target.value; setTimes(t); setFormUpdated([formUpdated[0],1]);}}/></td>
                        <td><TextField variant="standard" value={row['splits']} name={"splits..." + row['entry_id']} label="Split Name"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { let t = [...times]; t[`${i}`]['splits'] = event.target.value; setTimes(t); setFormUpdated([formUpdated[0],1]);}}/></td>
                        <td><TextField variant="standard" value={row['priority']} name={"priority..." + row['entry_id'] + "..." + row['priority']} label="Priority" sx={{width: 60}}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { let t = [...times]; t[`${i}`]['priority'] = event.target.value; setTimes(t); setFormUpdated([formUpdated[0],1]);}}/></td>        
                        <td><Checkbox type="checkbox" size="small" name={"include..." + row['entry_id']} value="1" checked={(row['include'] === "1") ? true : false} 
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { let t = [...times]; (event.target.checked) ? t[`${i}`]['include'] = "1" : t[`${i}`]['include'] = "0"; setTimes(t); setFormUpdated([formUpdated[0],1]);}}/></td></tr>
                        ))}
                        <tr>
                        <td><TextField variant="standard" name={"clock..." + racer['bib'] +"...-1..." + racer['event_id']} label="Time" onChange={() => {setFormUpdated([formUpdated[0],1]);}} /></td>
                        <td><TextField variant="standard" name="splits...-1" label="Split Name" onChange={() => {setFormUpdated([formUpdated[0],1]);}} />
                        <input type="hidden" name="priority...-1" value="1"/></td>
                        <td><Checkbox type="checkbox" size="small" name="include...-1" value="1" defaultChecked onChange={() => {setFormUpdated([formUpdated[0],1]);}}/></td></tr>

                        <tr>
                        <td><TextField variant="standard" name={"clock..." + racer['bib'] +"...-2..." + racer['event_id']} label="Time" onChange={() => {setFormUpdated([formUpdated[0],1]);}} /></td>
                        <td><TextField variant="standard" name="splits...-2" label="Split Name" onChange={() => {setFormUpdated([formUpdated[0],1]);}} />
                        <input type="hidden" name="priority...-2" value="1"/></td>
                        <td><Checkbox type="checkbox" size="small" name="include...-2" value="1" defaultChecked onChange={() => {setFormUpdated([formUpdated[0],1]);}} /></td></tr>

                        <tr>
                        <td><TextField variant="standard" name={"clock..." + racer['bib'] +"...-3..." + racer['event_id']} label="Time" onChange={() => {setFormUpdated([formUpdated[0],1]);}} /></td>
                        <td><TextField variant="standard" name="splits...-3" label="Split Name" onChange={() => {setFormUpdated([formUpdated[0],1]);}} />
                        <input type="hidden" name="priority...-3" value="1"/></td>
                        <td><Checkbox type="checkbox" size="small" name="include...-3" label="Active" value="1" defaultChecked onChange={() => {setFormUpdated([formUpdated[0],1]);}} /></td></tr>

                        
                    </tbody></table>

                    <Button type="submit" variant={(formUpdated[1] === 0) ? 'outlined' : 'contained'} size="small">Update Times</Button>
                    </form>
                    <span style={{fontSize: 10, marginTop: 20, marginLeft: 200, width: 240}}>Note: Times do not "update" or "delete", duplicate copies are made of original values and disabled, and a new active entry is created. To disable a time, uncheck it: this does not create a duplicate.</span>
                </div>
            </div>
            {/*<iframe srcDoc="<!DOCTYPE html>" src="about:blank" scrolling="no" frameborder="0" onLoad={() => clearForm()} id="hiddenFrame" name="hiddenFrame" style={{'opacity': 100, 'height': 100}} />*/}
            <iframe srcDoc="<!DOCTYPE html>" src="about:blank" scrolling="no" frameBorder="0" title="Hidden Frame" id="hiddenFrame" name="hiddenFrame" style={{'opacity': 0, 'height': 100}} />
        </div>


    );
};

export default Athlete;
