// src/components/Athlete.js
import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete, TextField, Checkbox, Button, Typography } from '@mui/material';
import { setDNS } from './api'; 
import { useLocation } from 'react-router-dom';

const Utilities = () => {
    const [status, setStatus] = useState('')


    // Load devices on first load only
    useEffect(() => {firstLoad();},[])

    const firstLoad = () => {
    }

    const submitDNS = (bibs) => {
        setDNS(bibs).then((val) => { 
            setStatus(JSON.stringify(val))
        })
    }

    return (
        <div>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}><h2>Utilities</h2></div>

                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography>DNS List</Typography>
                    <TextField variant="standard" name="bib" id="biblist" label="Comma Separated Bib List"/>
                    <Button variant="contained" sx={{width: 40, marginTop: 4}} onClick={() => {submitDNS(document.getElementById('biblist').value)}}>Submit</Button>
                    <Typography variant="caption" align="right">{status}</Typography>
                </div>

            
        </div>


    );
};

export default Utilities;
