// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */

/* Reset default margins and paddings */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

a {
    color: black;
}
a:visited {
    color: black;
}

/* Main container styles */
.app-container {
    display: flex;
    height: 100vh;
}

/* Sidebar styles */
.sidebar {
    position: fixed;
    width: 120px;
    background-image: linear-gradient(to right, #ffffff, #dfdfdf);
    padding: 20px;
    overflow-y: none;
    top: 0;
    bottom: 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    color: #000000;
}

.sidebar ul li {
    margin-bottom: 10px;
    cursor: pointer;
}

.sidebar ul li:hover {
    background-color: #c0c0c0;
}

/* Content styles */
.content {
    flex: 1 1;
    padding: 20px;
    margin-left: 160px;
}

.content h2 {
    margin-bottom: 20px;
}

.announcer {
    text-align: center;
}

.announcer button {
    margin: 5px;
    width: 18%;
    height: 60px;
    font-size: 26pt;    
}

.announcer .bibheader {
    font-size: 48pt;
    margin-bottom: 15px;
}

.announcer td {
    font-size: 30pt;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB,uCAAuC;AACvC;IACI,SAAS;IACT,UAAU;IACV,gCAAgC;AACpC;;AAEA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;;AAEA,0BAA0B;AAC1B;IACI,aAAa;IACb,aAAa;AACjB;;AAEA,mBAAmB;AACnB;IACI,eAAe;IACf,YAAY;IACZ,6DAA6D;IAC7D,aAAa;IACb,gBAAgB;IAChB,MAAM;IACN,SAAS;AACb;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,mBAAmB;AACnB;IACI,SAAO;IACP,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["/* src/App.css */\n\n/* Reset default margins and paddings */\nbody, html {\n    margin: 0;\n    padding: 0;\n    font-family: 'Arial', sans-serif;\n}\n\na {\n    color: black;\n}\na:visited {\n    color: black;\n}\n\n/* Main container styles */\n.app-container {\n    display: flex;\n    height: 100vh;\n}\n\n/* Sidebar styles */\n.sidebar {\n    position: fixed;\n    width: 120px;\n    background-image: linear-gradient(to right, #ffffff, #dfdfdf);\n    padding: 20px;\n    overflow-y: none;\n    top: 0;\n    bottom: 0;\n}\n\n.sidebar ul {\n    list-style-type: none;\n    padding: 0;\n    color: #000000;\n}\n\n.sidebar ul li {\n    margin-bottom: 10px;\n    cursor: pointer;\n}\n\n.sidebar ul li:hover {\n    background-color: #c0c0c0;\n}\n\n/* Content styles */\n.content {\n    flex: 1;\n    padding: 20px;\n    margin-left: 160px;\n}\n\n.content h2 {\n    margin-bottom: 20px;\n}\n\n.announcer {\n    text-align: center;\n}\n\n.announcer button {\n    margin: 5px;\n    width: 18%;\n    height: 60px;\n    font-size: 26pt;    \n}\n\n.announcer .bibheader {\n    font-size: 48pt;\n    margin-bottom: 15px;\n}\n\n.announcer td {\n    font-size: 30pt;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
